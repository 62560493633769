import React, {FC} from 'react'
import noop from 'lodash/noop'
import {
  DatePickerInput,
  Dropdown,
  DropdownTheme,
  StatesButton,
  StatesButtonStates,
  TextFieldTheme,
  ThreeDotsLoader,
} from 'wix-ui-tpa/cssVars'
import {useSettings} from '@wix/tpa-settings/react'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {settingsParams} from '../../../settingsParams'
import {ButtonAlignment, FormLayout} from '../../../../../types/createStylesParams'
import {reservationAddOnDataHooks} from '../../constants'
import {parseLocaleFromBM} from '../../../../../utils/locale'
import {LayoutSize} from '../../../../../components-shared/LayoutSizeProvider/types'

import {useHooks} from './useHooks'
import {classes, st} from './Form.st.css'

interface FormProps {
  enabledReservationLocations: ReservationLocation[]
  layoutSize: LayoutSize
  containerWidth: number
}

export const Form: FC<FormProps> = ({enabledReservationLocations, layoutSize, containerWidth}) => {
  const settings = useSettings()

  const {
    t,
    submitButtonContentWidth,
    isMobile,
    isLoading,
    regionalSettings,
    reservationLocationsOptions,
    partySizeOptions,
    timeOptions,
    selectedReservationLocation,
    selectedPartySize,
    selectedDate,
    selectedTime,
    handlePartySizeChange,
    handleDateChange,
    handleTimeChange,
    handleFindTableSubmit,
    handleLocationChange,
    filterDate,
  } = useHooks(enabledReservationLocations, layoutSize)

  // prevents layout flickering after initial render
  if (containerWidth === 0) {
    return null
  }

  const textFieldTheme = settings.get(settingsParams.textFieldTheme) as TextFieldTheme
  const buttonAlignment = settings.get(settingsParams.buttonAlignment)
  const buttonLabelText = settings.get(settingsParams.buttonLabelText)
  const showFormFieldLabels = settings.get(settingsParams.showFormFieldLabels)

  const formLayout = settings.get(settingsParams.formLayout)

  const formLayoutClass =
    formLayout === FormLayout.Packed || layoutSize === LayoutSize.XS || layoutSize === LayoutSize.S
      ? classes.packed
      : classes.strip

  const isTextFieldThemeBox = textFieldTheme === TextFieldTheme.Box
  const dropdownTheme = isTextFieldThemeBox ? DropdownTheme.Box : DropdownTheme.Line
  const datePickerTheme = isTextFieldThemeBox ? TextFieldTheme.Box : TextFieldTheme.Line

  const formRootClass = isTextFieldThemeBox ? classes.boxForm : classes.lineForm
  const dropDownClass = isTextFieldThemeBox ? classes.boxDropdown : classes.lineDropdown
  const datePickerClass = isTextFieldThemeBox ? classes.boxDatePicker : classes.lineDatePicker

  const buttonAlignmentClass = ButtonAlignmentToClass[buttonAlignment]

  return (
    <form
      className={st(classes.formRoot, formRootClass, formLayoutClass)}
      onSubmit={handleFindTableSubmit}
    >
      <div className={classes.formFieldsWrapper}>
        {reservationLocationsOptions.length > 1 && (
          <div className={classes.formField}>
            <Dropdown
              className={dropDownClass}
              aria-label={t('uou-reservations.add-on.location')}
              label={showFormFieldLabels ? t('uou-reservations.add-on.location') : undefined}
              options={reservationLocationsOptions}
              initialSelectedId={selectedReservationLocation?.id ?? undefined}
              onChange={handleLocationChange}
              theme={dropdownTheme}
            />
          </div>
        )}

        <div className={classes.formField}>
          <Dropdown
            className={dropDownClass}
            aria-label={t('uou-reservations.add-on.party')}
            label={showFormFieldLabels ? t('uou-reservations.add-on.party') : undefined}
            options={partySizeOptions}
            initialSelectedId={String(selectedPartySize)}
            onChange={handlePartySizeChange}
            theme={dropdownTheme}
          />
        </div>

        <div className={classes.formField}>
          <DatePickerInput
            locale={parseLocaleFromBM(regionalSettings)}
            className={datePickerClass}
            excludePastDates
            aria-label={t('uou-reservations.add-on.date')}
            label={showFormFieldLabels ? t('uou-reservations.add-on.date') : undefined}
            filterDate={filterDate}
            value={selectedDate}
            onChange={handleDateChange}
            inputTheme={datePickerTheme}
            inputWidth="100%"
            popoverPlacement={isMobile ? 'bottom' : 'bottom-start'}
            removeClearButton={true}
            zIndex={9999}
          />
        </div>

        <div className={classes.formField}>
          <Dropdown
            className={dropDownClass}
            aria-label={t('uou-reservations.add-on.time')}
            label={showFormFieldLabels ? t('uou-reservations.add-on.time') : undefined}
            options={timeOptions}
            initialSelectedId={selectedTime?.id}
            onChange={handleTimeChange}
            theme={dropdownTheme}
          />
        </div>
      </div>

      <div className={st(classes.buttonWrapper, buttonAlignmentClass)}>
        <StatesButton
          disabled={isLoading}
          type="submit"
          state={isLoading ? StatesButtonStates.IN_PROGRESS : StatesButtonStates.IDLE}
          idleContent={buttonLabelText}
          inProgressContent={<ThreeDotsLoader className={classes.loader} />}
          className={classes.formButton}
          data-hook={reservationAddOnDataHooks.submitButton()}
          style={{
            ...(submitButtonContentWidth ? {width: `${submitButtonContentWidth}px`} : {}),
          }}
          // glitch in WUT. It always takes onClick as a prop despite type="submit" on button
          onClick={noop}
        />
      </div>
    </form>
  )
}

const ButtonAlignmentToClass: Record<ButtonAlignment, string> = {
  [ButtonAlignment.Left]: classes.buttonLeft,
  [ButtonAlignment.Center]: classes.buttonCenter,
  [ButtonAlignment.Right]: classes.buttonRight,
  [ButtonAlignment.Stretch]: classes.buttonStretch,
}
