import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  PropsWithChildren,
} from 'react'

import {LayoutBreakpointsConfig, LayoutSize} from './types'

const getSize = (containerWidth: number, config: LayoutBreakpointsConfig): LayoutSize => {
  if (containerWidth < config.S) {
    return LayoutSize.XS
  } else if (containerWidth < config.M) {
    return LayoutSize.S
  } else if (containerWidth < config.L) {
    return LayoutSize.M
  } else {
    return LayoutSize.L
  }
}

interface ILayoutSizeContext {
  layoutSize: LayoutSize
  containerWidth: number
}

const LayoutSizeContext = createContext<ILayoutSizeContext>({
  layoutSize: LayoutSize.XS,
  containerWidth: 0,
})

const LayoutSizeProvider: React.FC<PropsWithChildren<{config: LayoutBreakpointsConfig}>> = ({
  children,
  config,
}) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      setContainerWidth(entry?.target.getBoundingClientRect().width ?? 0)
    })

    if (rootRef.current) {
      observer.observe(rootRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const layoutSize = useMemo(() => getSize(containerWidth, config), [containerWidth])

  return (
    <div id="table-reservations-page-root" ref={rootRef}>
      <LayoutSizeContext.Provider value={{layoutSize, containerWidth}}>
        {children}
      </LayoutSizeContext.Provider>
    </div>
  )
}

export const useGetLayoutSize = () => {
  const context = useContext(LayoutSizeContext)

  if (context === undefined) {
    throw new Error('useGetLayoutSize must be used within LayoutSizeProvider')
  }

  return useContext(LayoutSizeContext)
}

export const withLayoutSizeProvider =
  (ProvidedComponent: React.ElementType, config: LayoutBreakpointsConfig) =>
  <T extends object>(props: T) =>
    (
      <LayoutSizeProvider config={config}>
        <ProvidedComponent {...props} />
      </LayoutSizeProvider>
    )
